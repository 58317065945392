
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { mapActions, mapGetters } from 'vuex'
  import { Notification as NotificationEntity } from '@/entities/system/Notification'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { UserData } from '@/store/user/state'
  import dayjs from 'dayjs'
  import { Crumb } from '@/store/resources/form/state'

@Component({
  computed: {
    ...mapGetters('notification', ['systemNotifications']),
    ...mapGetters('app', ['isMini', 'isMobile']),
    ...mapGetters('user', ['user']),
  },
  methods: {
    ...mapActions('resources/form', ['setCrumbs']),
  },
})
  export default class NotificationSystem extends FilesProcess {
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  setCrumbs!: (crumbs: Array<Crumb>) => void
  // Obtener las notificaciones desde Vuex
  systemNotifications!: NotificationEntity[]

  seenNotifications: string[] = []

  isMini!: boolean
  isMobile!: boolean;
  // Estado del menú
  menu: boolean = false
  displayBroadcast: boolean = false

  count: number = 0

  user!: UserData

  // Métodos
  mounted () {
    const { notSeen } = this

    const localSeen = localStorage.getItem('seen-notifications')
      ?.replace('[', '')
      ?.replace(']', '')
      ?.split(',')
      ?.filter(item => item !== '')
    if (localSeen?.length) {
      this.seenNotifications = localSeen
      if (this.systemNotifications.every(notSeen)) {
        this.seenNotifications = []
      }
    } else {
      localStorage.setItem('seen-notifications', '[]')
    }
  }

  updateCount () {
    const { notSeen } = this

    this.count = this.notifications
      .filter(notSeen)
      .length
  }

  toggleMenu () {
    this.menu = !this.menu
  }

  seen (notification) {
    return notification.seen
  }

  notSeen (notification) {
    return !notification.seen
  }

  async markAsSeen (notification: NotificationEntity) {
    if (!(notification instanceof NotificationEntity)) return

    if (!notification.seen) {
      this.seenNotifications.push(notification.id)
    }
    if (notification.type !== 'personalized') return

    await this.deleteNotification(notification, this.user.id)
  }

  handleNotificationClick (link: string) {
    this.$router.push(link)
    this.setCrumbs([])

    // Cerrar el menú después de la acción
    this.menu = false
  }

  // Getters
  get notifications () {
    const { systemNotifications } = this

    return systemNotifications
      .filter(notification => notification.type !== 'broadcast')
      .sort((prev, next) => dayjs(next.date).diff(dayjs(prev.date)))
  }

  get broadcast () {
    const { systemNotifications } = this

    return systemNotifications
      .filter(notification => notification.type === 'broadcast' && !notification.seen)
      .sort((prev, next) => dayjs(next.date).diff(dayjs(prev.date)))
  }

  get notSeenBroadcast () {
    const { broadcast, notSeen } = this

    return broadcast.filter(notSeen)
  }

  getIcon (status: string | undefined): string {
    switch (status) {
      case 'unread':
        return 'mdi-email'
      case 'read':
        return 'mdi-email-open'
      default:
        return 'mdi-bell'
    }
  }

  @Watch('notifications', { deep: true })
  countWatcher () {
    this.updateCount()
  }

  @Watch('broadcast', { deep: true, immediate: true })
  updateBroadcast (val) {
    const { notSeen } = this

    this.displayBroadcast = val.some(notSeen)
  }

  @Watch('notSeenBroadcast.length', { deep: true })
  updateShowBroadcast (val) {
    this.displayBroadcast = Boolean(val)
  }

  @Watch('seenNotifications', { deep: true })
  updateSeenNotifications (val) {
    localStorage.setItem('seen-notifications', `[${val}]`)
    this.updateCount()
    this.displayBroadcast = Boolean(this.notSeenBroadcast.length)
  }
  }
